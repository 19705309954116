// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* colors.css */

:root {
    --primary-color: #3b2720;
    --secondary-color: #b58060;
    --accent-color: #d4a373;
    --background-color: #f4f1ea;
    --text-color: #2d1f18;
    --highlight-color: #a65a40;
    --border-color: #8c654b;
}
`, "",{"version":3,"sources":["webpack://./src/colors.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,uBAAuB;IACvB,2BAA2B;IAC3B,qBAAqB;IACrB,0BAA0B;IAC1B,uBAAuB;AAC3B","sourcesContent":["/* colors.css */\n\n:root {\n    --primary-color: #3b2720;\n    --secondary-color: #b58060;\n    --accent-color: #d4a373;\n    --background-color: #f4f1ea;\n    --text-color: #2d1f18;\n    --highlight-color: #a65a40;\n    --border-color: #8c654b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
