// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    h1 {
        text-align: center;
    }
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.instructionDocs {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.docToSlideContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: calc(100vh - 270px);
    width: 100%;
}

button {
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/components/Scorecards/Scorecards.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,gBAAgB;IAChB,iBAAiB;IACjB;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,8CAA8C;AAClD;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,8CAA8C;AAClD;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".inputForm {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    margin: 20px auto;\n    h1 {\n        text-align: center;\n    }\n}\n\nform {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.input {\n    margin-top: 20px;\n    padding: 10px;\n    width: 100%;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);\n}\n\n.instructionDocs {\n    margin-top: 20px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);\n}\n\n.docToSlideContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 20px;\n    height: calc(100vh - 270px);\n    width: 100%;\n}\n\nbutton {\n    border: none;\n    padding: 5px 5px;\n    border-radius: 5px;\n    background-color: #007bff;\n    color: white;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
