import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Social.css";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook} from '@fortawesome/free-brands-svg-icons';
const Social = () => {
  return (
    <div className="social">
        <div className={"icon"}>
            <a href={"https://www.instagram.com/beer_geek_trivia/"} rel="noreferrer" target={"_blank"}>
                <FontAwesomeIcon icon={faInstagram} />
            </a>
        </div>
        <div className={"icon"}>
            <a href={"https://www.facebook.com/profile.php?id=61554613388078"} rel="noreferrer" target={"_blank"}>
                <FontAwesomeIcon icon={faFacebook} />
            </a>
        </div>

    </div>
  );
}
export default Social;