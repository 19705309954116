import React, { useState } from 'react';
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const PresentationCreator = (props) => {
    const [googleDocUrl, setGoogleDocUrl] = useState('');
    const [googleSlidesUrl, setGoogleSlidesUrl] = useState('');

    const validateUrls = () => {
        const googleDocPattern = /^https:\/\/docs\.google\.com\/document\/d\/[a-zA-Z0-9_-]+/;
        const googleSlidesPattern = /^https:\/\/docs\.google\.com\/presentation\/d\/[a-zA-Z0-9_-]+/;

        if (!googleDocPattern.test(googleDocUrl)) {
            toast.error('Invalid Google Doc URL');
            return false;
        }

        if (!googleSlidesPattern.test(googleSlidesUrl)) {
            toast.error('Invalid Google Slides URL');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateUrls()) {
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/presentations/create`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ doc_url: googleDocUrl, pres_url: googleSlidesUrl, token: props.token }),
            });

            if (!response.ok) {
                toast.error('Error creating presentation');
                throw new Error('Failed to create presentation');
            }

            toast.success('Presentation created successfully');
        } catch (error) {
            console.error('Error creating presentation:', error.message);
            toast.error('Error creating presentation');
        }
    };

    const handleSlideUrlChange = (e) => {
        setGoogleSlidesUrl(e.target.value);
    };

    const handleDocUrlChange = (e) => {
        setGoogleDocUrl(e.target.value);
    };

    return (
        <div className="docToSlideContainer">
            <div className="instructionDocs">
                <h1>Instructions</h1>
                <ol>
                    <li>Ensure your beergeektrivia.com account has access to the Google Doc and Google Slide you want to use.</li>
                    <ol>
                        <li>An easy way to ensure this is to put both in the shared trivia drive</li>
                        <li>Alternatively directly share them to the beergeektrivia account</li>
                        <li>Another option is to just create them using the beergeektrivia account</li>
                    </ol>
                    <li>Grab the whole url for both files using Ctrl+L and then Ctrl+A then Ctrl+X or Ctrl+C</li>
                    <li>Paste the Google Doc URL into the first input field</li>
                    <li>Paste the Google Slide URL into the second input field</li>
                    <li>Click the Create Presentation button</li>
                    <li>Wait for the success or error message (this is only sent after the whole presentation is created)</li>
                </ol>
                <h3>Notes</h3>
                <ul>
                    <li>You can watch the slides being created on the google slideshow before the success or error message</li>
                    <li>If you receive an error try again after 1 minute, if you continue to get errors contact Ryan at (970)-712-8362</li>
                </ul>
            </div>

            <div className="inputForm">
                <h1>Convert Google Doc to Google Slide</h1>
                <form onSubmit={handleSubmit}>
                    <label>Google Doc URL</label>
                    <input
                        type="text"
                        placeholder="Enter Google Doc URL"
                        value={googleDocUrl}
                        onChange={handleDocUrlChange}
                        className="input"
                    />
                    <label>Google Slides URL</label>
                    <input
                        type="text"
                        placeholder="Enter Google Slides URL"
                        value={googleSlidesUrl}
                        onChange={handleSlideUrlChange}
                        className="input"
                    />
                    <button type="submit" className="button">Create Presentation</button>
                </form>
            </div>
        </div>
    );
};

PresentationCreator.propTypes = {
    token: PropTypes.string.isRequired,
};

export default PresentationCreator;