// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social {
    height: 50px;
    background-color: var(--accent-color);
    align-items: center;
    display: flex;
    justify-content: center;
}

.icon {
    margin-left: 10px;
    font-size: 30px;
}

a {
    color: var(--text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Social/Social.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["@import url('../../colors.css');\n\n.social {\n    height: 50px;\n    background-color: var(--accent-color);\n    align-items: center;\n    display: flex;\n    justify-content: center;\n}\n\n.icon {\n    margin-left: 10px;\n    font-size: 30px;\n}\n\na {\n    color: var(--text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
