import React from "react";
import PropTypes from 'prop-types';
import Header from "../Header/Header";
import Social from "../Social/Social";


const MainLayout = ({ children }) => {
    return (
        <div style={{background: "#f4f1ea"}}>
            <Header/>
            <div>
                {children}
            </div>
            <Social />
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;