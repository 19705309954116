import React, { forwardRef } from "react";
import imageOne from "../../assets/Trvia-Night-1080p-color-0006.jpg"
import imageTwo from "../../assets/Trvia-Night-1080p-color-0020.jpg"
import imageThree from "../../assets/Trvia-Night-1080p-color-0024.jpg"
import imageFour from "../../assets/Trvia-Night-1080p-color-0026.jpg"
import imageFive from "../../assets/Trvia-Night-1080p-color-0028.jpg"

import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
//import ExampleCarouselImage from 'components/ExampleCarouselImage';

const Home = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div className="carouselContainer">
        <Carousel controls={false} touch={true} interval={3000} fade={true}>
          <Carousel.Item>
            <img className="home offset-one" src={imageOne} />
          </Carousel.Item>
          <Carousel.Item>
            <img className="home offset-two" src={imageTwo}/>
          </Carousel.Item>
          <Carousel.Item>
            <img className="home offset-three" src={imageThree}/>
          </Carousel.Item>
          <Carousel.Item>
            <img className="home offset-four" src={imageFour}/>
          </Carousel.Item>
          <Carousel.Item>
            <img className="home offset-five" src={imageFive}/>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
});

Home.displayName = "Home";

export default Home;
