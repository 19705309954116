import React, { useState, useEffect } from "react";
import {toast} from "react-toastify";
import styles from "./checkscores.css";

const CheckScores = () => {
    const [formData, setFormData] = useState({ pin: "" });
    const [pinState, setPinState] = useState(false);
    const [pinData, setPinData] = useState([]);
    let submitter = [];

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pin = urlParams.get('pin');
        if (pin) {
            setFormData({ pin });
            handleSubmit(null, pin);
        }
    }, []);

    const handleSubmit = async (e, pin) => {
        if (e) e.preventDefault();
        const pinToUse = pin || formData.pin;
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/get_all_teams?pin=${encodeURIComponent(pinToUse)}`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const data = await response.json();

            submitter = Object.entries(data).map(([key, value]) => ({
                key,
                name: value.name,
                playerCount: value.player_count,
                score: value.score
            }));

            // Sort teams by score in descending order
            submitter.sort((a, b) => b.score - a.score);
            let currentPlace = 1;
            let previousScore = null;
            submitter = submitter.map((team, index) => {
                if (team.score !== previousScore) {
                    currentPlace = index + 1;
                }
                previousScore = team.score;
                return { ...team, place: currentPlace };
            });


            // Filter out teams with fewer than 6 players
            let eligibleTeams = submitter.filter(team => team.playerCount < 6);

            // Sort eligible teams by score
            eligibleTeams.sort((a, b) => b.score - a.score);
            eligibleTeams = eligibleTeams.slice(2)

            // Select the best small team not in first or second place
            let bestSmallTeam = null;
            for (let team of eligibleTeams) {
                if (team.playerCount < 4) {
                    bestSmallTeam = team;
                    break;
                }
            }

            // Mark the best small team
            if (bestSmallTeam) {
                submitter = submitter.map(team => ({
                    ...team,
                    is_best_small_team: team.key === bestSmallTeam.key
                }));
            }

            setPinState(true);
            setPinData(submitter);

            if (!response.ok) {
                toast.error('Error with Pin');
                return;
            }

            toast.success('Pin Found');
            window.history.pushState(null, '', `?pin=${encodeURIComponent(pinToUse)}`);

        } catch (error) {
            console.error('Error finding pin:', error.message);
            toast.error('Error finding pin');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="scoresheet">
            {pinState ? <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>Place</th>
                        <th>Name</th>
                        <th>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pinData.map((team) => (
                        <tr key={team.key} className={styles.trStyle}>
                            <td className={styles.tdStyle}>{team.place}</td>
                            <td className={styles.tdStyle}>{team.is_best_small_team ? "🏆" : team.playerCount > 6 ? "❌": ""} {team.name}</td>
                            <td className={styles.tdStyle}>{team.score}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "20px"}}>
                        <div>🏆 = Best Small Team</div>
                        <div>❌ = Team too big to win</div>
                    </div>
                </div>

            </div> : (<div className="pinInput" style={{width: "70vw", margin: "auto"}}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-3">
                            <label htmlFor="pin" className="inputLabel">Quiz Pin</label>
                            <input
                                type="text"
                                className="form-control"
                                id="pin"
                                name="pin"
                                value={formData.pin}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="submitButton">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>

                        <span>Please enter pin given by the Quiz Master</span>
                    </form>
                </div>
            )}
        </div>
    );
};

export default CheckScores;