import React, { useEffect, useState, useMemo } from 'react';
import {toast} from 'react-toastify';
import './Scorecards.css';
import PropTypes from 'prop-types';


const Scorecards = (props)  => {
    const [sheetUrl, setSheetUrl] = useState('');
    const [games, setGames] = useState([]);
    const [venue, setVenue] = useState('Handlebar');
    const handleSheetUrlChange = (e) => {
        setSheetUrl(e.target.value);
    };

    useEffect(() => {
        if (props.token) {
            handleGetAllGames();
        }
    }, [props.token]);

    const handleUpdate = async (gameId) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/update_game`;
        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ game_id: gameId, token: props.token }),
            });

            if (!response.ok) {
                toast.error('Error updating game');
                throw new Error('Failed to update game');
            }
            const data = await response.json();
            setGames(data);

            toast.success('Game updated successfully');
        } catch (error) {
            console.error('Error updating game:', error.message);
            toast.error('Error updating game');
        }
    };
    const handleGetAllGames = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/get_all_games`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                toast.error('Error getting all games');
                throw new Error('Failed to get all games');
            }

            const data = await response.json();
            setGames(data);
            toast.success('All games fetched successfully');
        } catch (error) {
            console.error('Error getting all games:', error.message);
            toast.error('Error getting all games');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/create_game`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: sheetUrl, venue: venue }),
            });



            if (!response.ok) {
                toast.error('Error creating game');
                throw new Error('Failed to create game');
            }

            toast.success('Game created successfully');
            const data = await response.json();
            setGames(data);
        } catch (error) {
            console.error('Error creating game:', error.message);
            toast.error('Error creating game');
        }
    };
    const handleDelete = async (gameId) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/delete_game`;
        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ game_id: gameId }),
            });

            if (!response.ok) {
                toast.error('Error deleting game');
                throw new Error('Failed to delete game');
            }

            const data = await response.json();
            setGames(data);
            toast.success('Game deleted successfully');
        } catch (error) {
            console.error('Error deleting game:', error.message);
            toast.error('Error deleting game');
        }
    }

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const [filter, setFilter] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const handleVenueChange = (e) => {
        setVenue(e.target.value);
    }

    const handleVenueFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedAndFilteredGames = useMemo(() => {
        let filteredGames = games;

        if (filter) {
            filteredGames = filteredGames.filter((game) => game.venue === filter);
        }

        if (sortConfig.key) {
            filteredGames.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredGames;
    }, [games, filter, sortConfig]);


    return (
        <div>
            <div className="inputForm">
                <h1>Add Scorecard </h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Enter Google Sheets URL"
                        value={sheetUrl}
                        onChange={handleSheetUrlChange}
                        className="input"
                    />
                    <select onChange={handleVenueChange} className="select">
                        <option value="">Select Venue</option>
                        <option value="Handlebar">Handlebar</option>
                        <option value="Gemini">Gemini</option>
                        <option value="Palisade">Palisade</option>
                        <option value="Cruise Control">Cruise Control</option>
                    </select>
                    <button type="submit" className="button">Create Game</button>
                </form>
            </div>
            <div>
                <h5>Filter by Venue</h5>
                <select onChange={handleVenueFilterChange} className="select">
                    <option value="">All Venues</option>
                    <option value="Handlebar">Handlebar</option>
                    <option value="Gemini">Gemini</option>
                    <option value="Palisade">Palisade</option>
                    <option value="Cruise Control">Cruise Control</option>
                </select>
            </div>
                <div>
                    <table>
                        <thead>
                        <tr>

                            <th onClick={() => handleSort('venue')}>Venue</th>
                            <th onClick={() => handleSort('createdAt')}>Created
                                At {sortConfig.key === 'createdAt' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}</th>
                            <th onClick={() => handleSort('modifiedAt')}>Modified
                                At {sortConfig.key === 'modifiedAt' ? sortConfig.direction === "ascending" ? "⬆️" : "⬇️" : ""}</th>
                            <th>Pin</th>
                            <th>URL</th>
                            <th>Update</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sortedAndFilteredGames.map((game) => (
                            <tr key={game.id}>
                                <td>{game.venue}</td>
                                <td>
                                    {new Date(game.createdAt).toLocaleDateString('en-US', options)}
                                </td>
                                <td>
                                    {new Date(game.modifiedAt).toLocaleDateString('en-US', options)}
                                </td>
                                <td>{game.pin}</td>
                                <td>
                                    <a href={game.url} rel="noreferrer" target="_blank">
                                        <button>
                                            Link to sheet
                                        </button>
                                    </a>
                                </td>
                                <td>
                                    <button onClick={() => handleUpdate(game.id)}>Update</button>
                                </td>
                                <td>
                                    <button style={{backgroundColor: "rgb(250,0,0,.7)"}}
                                            onClick={() => handleDelete(game.id)}>🗑️
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
        </div>
    );
}
Scorecards.propTypes = {
    token: PropTypes.string.isRequired,
};
export default React.memo(Scorecards);
